import React from "react";

import Plyr from "plyr-react";
import "plyr-react/plyr.css"
import "../../../styles/plyr.css";
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';

import { getVideoAds, loadVideo } from "utils/video.js";


const styles = theme => ({
  videoContainer: {
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  liveVideo: {
    flex: "1 1 auto",
    maxWidth: "100%",
    aspectRatio: "16/9",
    border: "none",
    [theme.breakpoints.up('md')]: {
      minWidth: "448px",
    },
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
      flex: "1 1 100%",
      width: "100%",
    },
  },
});


class Video extends React.PureComponent {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.state = {
      hasAds: undefined,
      source: loadVideo(this.props.source),
      sourceAd: null,
      isPlaying: false,
    };
    this.checkPlayer = null;
  }

  componentDidMount() {
    const ads = getVideoAds(this.props.code);
    this.setState({
      hasAds: ads !== null,
      sourceAd: ads ? loadVideo(ads) : null,
    }, () => {
      this.checkPlayer = setInterval(() => {
        if (this.player.current && this.player.current.plyr && this.player.current.plyr.on) {
          clearInterval(this.checkPlayer)
          this.checkPlayer = null;

          this.player.current.plyr.poster = this.state.source.poster
          this.player.current.plyr.on("ended", () => {
            if (this.state.sourceAd) {
              this.setState({ hasAds: false });
              return;
            }
          });
        }
      }, 50)
    })
  }

  render() {
    const { classes } = this.props

    if (!this.props.source) {
      return <span></span>
    }

    return (
      <div className={classes.videoContainer}>
        {this.state.hasAds === true && (
          <div className={classes.liveVideo}>
            <Plyr
              ref={(player) => (this.player.current = player)}
              source={this.state.sourceAd}
              options={{
                controls: [
                  'play-large',
                  'play',
                  'mute',
                  'fullscreen',
                ],
                playsinline: true,
                hideControls: true,
                clickToPlay: false,
              }}
            />
          </div>
        )}

        {this.state.hasAds === false && this.state.source.type === "iframe" &&
          <iframe title="ads" className={classes.liveVideo} src={this.state.source.src} allowFullScreen>
          </iframe>
        }

        {this.state.hasAds === false && this.state.source.type !== "iframe" &&
          <div className={classes.liveVideo}>
            <Plyr
              ref={(player) => (this.player.current = player)}
              source={loadVideo(this.props.source)}
              options={{
                controls: [
                  'play-large',
                  'play',
                  'progress',
                  'current-time',
                  'duration',
                  'mute',
                  'volume',
                  'download',
                  'fullscreen',
                ],
                playsinline: true,
                hideControls: true,
                clickToPlay: true,
              }}
              autoPlay={this.state.sourceAd ? true : false}
            />
          </div>
        }
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(Video))

import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@mui/styles/withStyles';
import Loader from 'components/ui/Loader';


const styles = theme => ({
  searching: {
    position: "absolute",
    top: "50%",
  },
});


class Searching extends React.Component {

  render() {
    const { classes } = this.props

    return (
      <Loader class={classes.searching} color="secondary" />
    );
  }
}

Searching.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Searching)

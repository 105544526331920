import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, ButtonGroup, MenuItem, TextField, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

import { withNavigate } from 'components/hooks/HOC.js'
import { DISCIPLINES, MONTHS, YEARS } from 'constants/constants.js'
import API from 'services/Api.js'
import ContestCard from '../contest/ContestCard.js'
import Footer from 'components/layout/Footer.js'
import Header from 'components/layout/Header.js'
import default_logo_morfphology from 'assets/contest/default_morphology.png'
import default_logo_dressage from 'assets/contest/default_dressage.png'
import Loader from 'components/ui/Loader.js'
import ReactGA from "react-ga4"
import RankingButton from "components/pages/ranking/RankingButton.js";


const styles = theme => ({
  input: {
    margin: 8,
    width: 290,
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      margin: 8,
    }
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: "right",
    fontFamily: "Yantramanav",
    fontSize: 16,
    fontWeight: "bold",
    padding: "4px 16px",
  },
  boxBackground: {
    background: "#f1f1f1",
    padding: 8,

  },
  masonry: {
    margin: "16px auto",
  },
  btnGroupSections: {
    textAlign: "center",
    borderRadius: 0,
    fontWeight: "bold",
    marginBottom: 16,
  },
  btnSearch: {
    borderRadius: 0,
    height: 40,
  },
  btnSearchSelected: {
    borderRadius: 0,
    height: 40,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    }
  },
  mt: {
    marginTop: 16,
  },
});


class ListContest extends React.Component {
  constructor(props) {
    super(props)
    let query = new URLSearchParams(this.props.location.search)
    this.state = {
      query: query,
      contests: [],
      search_participant: '',
      search_livestock: '',
      search_contest: '',
      search_judge: '',
      search_month: query.get('month') || new Date().getMonth() + 1,
      search_year: query.get('year') || new Date().getFullYear(),
      currentSearch: "",
      limit: 50,
      offset: 0,
      max_offset: 0,
      judges: [],
      searching: false
    }
  }

  componentDidMount() {
    this.loadJudges()
    this.setState({...this.state, searching: true});
    this.loadContests(this.props.location.search)
    ReactGA.send({
      hitType: "pageview",
      page: `/contests/${this.props.params.discipline}`,
      title: "ListContest",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({...this.state, searching: true});
      this.loadContests(this.props.location.search)
    }
  }

  clear = () => {
    this.setState({
      search_participant: '',
      search_livestock: '',
      search_contest: '',
      search_judge: '',
      search_month: '',
      search_year: '',
    })
  }

  loadJudges = () => {
    API.judge.getList()
      .then(resp => {
        this.setState({ judges: resp })
      })
      .catch(error => {
        console.error("API: Error loading judges");
      });
  }

  loadContests = (query) => {
    this.clear()
    API.contest.getContests(this.props.params.discipline, query)
      .then(resp => {
        let max_offset = 0
        if (resp.length > 0) {
          max_offset = parseInt(resp[0]['amount_rows'] / this.state.limit)
        }
        this.setState({
          contests: resp,
          max_offset: max_offset,
          searching: false
        })
      })
      .catch(error => {
        alert("Error loading contests with query: " + query);
        this.setState({
          searching: false
        })
      });
  }

  getQueryFromParams = (params) => {
    // Filters for remove "", false, null and undefined values
    let validParams = Object.entries(params).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
    return new URLSearchParams(validParams).toString()
  }

  getCurrentMonth = () => {
    let query = new URLSearchParams(this.props.location.search)
    return query.get('month')
  }

  getCurrentYear = () => {
    let query = new URLSearchParams(this.props.location.search)
    return query.get('year')
  }

  search = () => {
    const discipline = this.props.params.discipline
    let params = {
      'livestock': this.state.search_livestock,
      'name': this.state.search_contest,
      'judge': this.state.search_judge,
      'year': this.state.search_year,
      'month': this.state.search_month,
    }

    // Default: search contest, update query
    let pathname = `/contests/${discipline}`
    if (this.state.currentSearch === "Juez") {
      params['ejname'] = this.state.search_participant
      pathname = '/judge'
    } else if (this.state.currentSearch === "Ejemplar") {
      params['name'] = this.state.search_participant
      pathname = '/participant'
    }
    this.props.navigate(pathname + '?' + this.getQueryFromParams(params))
  }

  loadMore = () => {
    let params = new URLSearchParams(this.props.location.search)
    params.set('offset', (this.state.offset + 1) * this.state.limit)
    this.setState({searching: true});
    API.contest.getContests(this.props.params.discipline, '?' + params.toString())
      .then(resp => {
        let max_offset = 0
        if (resp.length > 0) {
          max_offset = parseInt(resp[0]['amount_rows'] / this.state.limit)
        }
        this.setState({
          contests: this.state.contests.concat(resp),
          offset: this.state.offset + 1,
          max_offset: max_offset,
          searching: false
        })
      })
      .catch(error => {
        alert("Error loading contests with query: " + params.toString());
      });
  }

  handleParticipant = (event) => {
    this.setState({ search_participant: event.target.value })
  }

  handleLivestock = (event) => {
    this.setState({ search_livestock: event.target.value })
  }

  handleContest = (event) => {
    this.setState({ search_contest: event.target.value })
  }

  handleJudge = (event) => {
    this.setState({ search_judge: event.target.value })
  }

  handleYear = (event) => {
    this.setState({ search_year: event.target.value })
  }

  handleMonth = (event) => {
    this.setState({ search_month: event.target.value })
  }

  changeSearch = (search) => {
    this.setState({ currentSearch: search })
  }

  render() {
    const { classes } = this.props
    const discipline = this.props.params.discipline
    const rankingButtonEnable = ["morf", "doma", "ae", "dv", "et"].includes(discipline)

    let desc = "Concursos " + DISCIPLINES[discipline]
    let current_month = this.getCurrentMonth()
    let current_year = this.getCurrentYear()
    let rankingTitle = "Ver Ranking"
    let rankingUrl = "/" + discipline + "/ranking"
    if (current_month) {
      desc += " " + MONTHS[current_month]
    }
    if (current_year) {
      desc += " " + current_year
    }

    let default_contest = default_logo_morfphology
    if (discipline === 'doma') {
      default_contest = default_logo_dressage
    }

    let search_fields = ["Concurso", "Ejemplar"]
    if (discipline === 'morf') {
      search_fields.push("Juez")
      rankingTitle = "Clasificados SICAB"
    }

    let now = new Date()
    now.setHours(0, 0, 0, 0)

    let tomorrow = new Date(now.valueOf() + 1 * 24 * 60 * 60 * 1000)
    let comming_soon = new Date(now.valueOf() + 10 * 24 * 60 * 60 * 1000)

    let contest_in_live = []
    let contest_comming_soon = []
    let contests = []
    let start_day, start_month, start_year
    let end_day, end_month, end_year
    for (let contest of this.state.contests) {
      [start_day, start_month, start_year] = contest.start.split('/');
      [end_day, end_month, end_year] = contest.end.split('/');
      let start = new Date(start_year, start_month - 1, start_day)
      let end = new Date(end_year, end_month - 1, end_day)

      if (start <= now && now <= end) {
        if (!contest_in_live.some((elem) => elem.code === contest.code)) {
          contest_in_live.push(contest)
        }
      } else if (tomorrow <= start && start <= comming_soon) {
        if (!contest_comming_soon.some((elem) => elem.code === contest.code)) {
          contest_comming_soon.push(contest)
        }
      } else {
        contests.push(contest)
      }
    }

    return (
      (<Box>
        <Header info={DISCIPLINES[discipline]} />
        { rankingButtonEnable &&
          <RankingButton title={rankingTitle} url={rankingUrl} />
        }
        {/* SEARCH */}
        <Box sx={{
          mb: 2
        }}>
          <ButtonGroup className={classes.btnGroupSections} color="secondary">
            {search_fields.map((s, i) => {
              return (
                <Button
                  key={i}
                  className={
                    s === this.state.currentSearch
                      ? classes.btnSearchSelected
                      : classes.btnSearch
                  }
                  onClick={() => this.changeSearch(s)}
                >
                  <Typography variant="h6">Buscar {s}</Typography>
                </Button>
              );
            })}
          </ButtonGroup>

          <form className={classes.form} autoComplete="off">
            <Box>
              {/* JUDGE */}
              {this.state.currentSearch === "Juez" && discipline === "morf" && (
                <TextField
                  select
                  className={classes.input}
                  onChange={this.handleJudge}
                  value={this.state.search_judge}
                  label="Juez"
                  variant="outlined"
                >
                  <MenuItem value="">-</MenuItem>
                  {this.state.judges.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {/* PARTICIPANT */}
              {["Ejemplar", "Juez"].includes(this.state.currentSearch) && (
                <TextField
                  className={classes.input}
                  value={this.state.search_participant}
                  onChange={this.handleParticipant}
                  label="Ejemplar"
                  variant="outlined"
                />
              )}

              {/* CONTEST */}
              {["Concurso", "Juez"].includes(this.state.currentSearch) && (
                <TextField
                  className={classes.input}
                  value={this.state.search_contest}
                  onChange={this.handleContest}
                  label="Concurso"
                  variant="outlined"
                />
              )}

              {/* LIVESTOCK */}
              {this.state.currentSearch === "Ejemplar" &&
                discipline === "morf" && (
                  <TextField
                    className={classes.input}
                    value={this.state.search_livestock}
                    onChange={this.handleLivestock}
                    label="Gandería titular"
                    variant="outlined"
                  />
                )}

              {/* MONTH */}
              {["Concurso", "Juez"].includes(this.state.currentSearch) && (
                <TextField
                  select
                  className={classes.input}
                  onChange={this.handleMonth}
                  value={this.state.search_month}
                  label="Mes"
                  variant="outlined"
                >
                  <MenuItem value="">-</MenuItem>
                  {Object.entries(MONTHS).map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                      {option[1]}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {/* YEAR */}
              {["Concurso", "Juez"].includes(this.state.currentSearch) && (
                <TextField
                  select
                  className={classes.input}
                  onChange={this.handleYear}
                  value={this.state.search_year}
                  label="Año"
                  variant="outlined"
                >
                  <MenuItem value="">-</MenuItem>
                  {YEARS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>

            {["Concurso", "Juez", "Ejemplar"].includes(
              this.state.currentSearch
            ) && (
              <Box>
                <Button
                  className={classes.button}
                  onClick={this.clear}
                  color="primary"
                  variant="contained"
                >
                  Limpiar
                </Button>
                <Button
                  className={classes.button}
                  onClick={this.search}
                  color="primary"
                  variant="contained"
                >
                  Buscar
                </Button>
              </Box>
            )}
          </form>
        </Box>
        {/* CONTESTS IN LIVE */}
        {contest_in_live.length > 0 && [
          <Typography key={1} className={classes.mt} variant="subtitle1">
            En Directo
          </Typography>,
          <Box key={2} className={classes.boxBackground}>
            <Box
              style={{
                display: "flex",
                flexFlow: "wrap",
                justifyContent: "center",
              }}
            >
              {contest_in_live.map((c, i) => {
                return (
                  <ContestCard
                    key={i}
                    img={
                      c.logo
                        ? process.env.REACT_APP_MEDIA_URL + c.logo
                        : default_contest
                    }
                    alt=""
                    name={c.name}
                    cat={c.cat}
                    city={c.town + " / " + c.city + " / " + c.country}
                    start={c.start}
                    end={c.end}
                    jmorf={c.jmorf}
                    jfunc={c.jfunc}
                    code={c.code}
                    st={c.st}
                    discipline={discipline}
                    border="live"
                  />
                );
              })}
            </Box>
          </Box>,
        ]}
        {/* CONTESTS COMMING SOON */}
        {contest_comming_soon.length > 0 && [
          <Typography key={3} className={classes.mt} variant="subtitle1">
            Próximamente
          </Typography>,
          <Box key={4} className={classes.boxBackground}>
            <Box
              style={{
                display: "flex",
                flexFlow: "wrap",
                justifyContent: "center",
              }}
            >
              {contest_comming_soon.map((c, i) => {
                return (
                  <ContestCard
                    key={i}
                    img={
                      c.logo
                        ? process.env.REACT_APP_MEDIA_URL + c.logo
                        : default_contest
                    }
                    alt=""
                    name={c.name}
                    cat={c.cat}
                    city={c.town + " / " + c.city + " / " + c.country}
                    start={c.start}
                    end={c.end}
                    jmorf={c.jmorf}
                    jfunc={c.jfunc}
                    code={c.code}
                    st={c.st}
                    discipline={discipline}
                    border="soon"
                  />
                );
              })}
            </Box>
          </Box>,
        ]}
        {/* CONTESTS */}
        <Typography className={classes.mt} variant="subtitle1">
          {desc}
        </Typography>
        <Box className={classes.boxBackground}>
          <Box
            style={{
              display: "flex",
              flexFlow: "wrap",
              justifyContent: "center",
            }}
          >
            {contests.map((c, i) => {
              return (
                <ContestCard
                  key={i}
                  img={
                    c.logo
                      ? process.env.REACT_APP_MEDIA_URL + c.logo
                      : default_contest
                  }
                  alt=""
                  name={c.name}
                  cat={c.cat}
                  city={c.town + " / " + c.city + " / " + c.country}
                  start={c.start}
                  end={c.end}
                  jmorf={c.jmorf}
                  jfunc={c.jfunc}
                  code={c.code}
                  st={c.st}
                  discipline={discipline}
                />
              );
            })}
          </Box>

          {this.state.searching && <Loader class={classes.waiting} />}

          {!this.state.searching && this.state.contests.length === 0 && (
            <Typography variant="h5">No se encontraron resultados</Typography>
          )}
        </Box>
        {this.state.max_offset > this.state.offset && (
          <Button
            className={classes.button}
            onClick={this.loadMore}
            color="primary"
            variant="contained"
          >
            Cargar más
          </Button>
        )}
        <Footer />
      </Box>)
    );
  }
}

ListContest.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withNavigate(ListContest))

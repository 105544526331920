export const loadVideo = (video) => {
  if (video.includes('youtu.be')) {
    return {
      type: 'video',
      sources: [{
        src: video,
        provider: 'youtube',
      }],
      poster: 'https://img.youtube.com/vi/' + video.split('/').pop() + '/maxresdefault.jpg',
    }
  } else if (video.includes('vimeo')) {
    return {
      type: 'video',
      sources: [{
        src: video,
        provider: 'vimeo',
      }],
      poster: 'https://vumbnail.com/' + video.split('/').pop() + '.jpg',
    }
  } else if (video.includes('clipmyhorse.tv')) {
    return {type: 'iframe', src: video}
  } else {
    return {
      type: 'video',
      sources: [{
        src: video,
        type: 'video/mp4',
      }]
    }
  }
}


const isVideoAdsUpdated = () => {
  const lastUpdated = localStorage.getItem('video-ads-updated')
  if (!lastUpdated) return false
  return (Date.now() - lastUpdated) < (5 * 60 * 1000)  // five minutes
}

const updateVideoAds = async () => {
  const res = await fetch(process.env.REACT_APP_API_URL + '/videos/ads/all/')
  const data = await res.json()
  localStorage.setItem('video-ads', JSON.stringify(data))

  const lastUpdated = Date.now()
  localStorage.setItem('video-ads-updated', lastUpdated)
}

export const getVideoAds = (code) => {
  if (!isVideoAdsUpdated()) {
    updateVideoAds()
  }

  const data = localStorage.getItem('video-ads')
  if (!data) return null

  const ads = JSON.parse(data)[code]
  if (!ads) return null

  return ads[Math.floor(Math.random() * ads.length)]  // get random ads
}

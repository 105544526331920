import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

import Grid from '@mui/material/Grid2';
import { CardActionArea, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
  card: {
    maxWidth: 400,
    height: 360,
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    [theme.breakpoints.down('lg')]: {
      height: 250,
      maxWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      height: 150,
      maxWidth: 200,
    },
  },
  media: {
    width: 232,
    height: 232,
    margin: "20px 84px 0 84px",
    objectFit: "contain",
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      width: 250,
      height: 180,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: 140,
      height: 100,
    },
  },
  typeButton: {
    borderRadius: 100,
    backgroundColor: "#e6ac13",
    color: "#c60637",
    height: 56,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
    width: "fit-content",
    margin: "auto",
    padding: "0 32px 0 32px",
    lineHeight: 1.33,
    [theme.breakpoints.down('lg')]: {
      height: 42,
      fontSize: 14,
      maxWidth: 220
    },
    [theme.breakpoints.down('sm')]: {
      padding: "0 12px 0 12px",
      height: 32,
      fontSize: 12,
      maxWidth: 100
    },
  }
});


class ContestTypeCard extends React.Component {
  render() {
    const { classes } = this.props
    const currentYear = new Date().getFullYear()

    return (
      <Grid>
        <CardActionArea className={classes.card} component={Link}
                        to={`/contests/${this.props.discipline}?year=${currentYear}`}>
          <img
            className={classes.media}
            alt={this.props.alt}
            src={this.props.img}
          />
          <Typography className={classes.typeButton} variant="h6">{ this.props.title }</Typography>
        </CardActionArea>
      </Grid>
    )
  }
}

ContestTypeCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContestTypeCard)

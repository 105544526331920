import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { Box, Snackbar } from '@mui/material'
import { Alert } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import Ads from './services/Ads.js'
import Contest from 'components/pages/contest/Contest.js'
import Home from 'components/pages/home/Home.js'
import Judge from 'components/pages/judge/Judge.js'
import ListContest from './components/pages/contest-list/ListContest.js'
import Participant from './components/pages/participant/Participant.js'
import Ranking from 'components/pages/ranking/Ranking.js'
import ReactGA from "react-ga4"
import RankingMorf from 'components/pages/ranking/RankingMorf.js'
import RankingOD from 'components/pages/ranking/RankingOD.js'


const styles = theme => ({
  app: {
    textAlign: "center",
  },
  btnUpdate: {
    border: "1px solid green",
    padding: "2px 4px",
    marginLeft: theme.spacing(1),
    backgroundColor: "seagreen",
    color: "white",
  },
});


const App = (props) => {
  const updateAPP = () => {
    const registrationWaiting = props.swRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload()
        }
      })
    }
  }

  const swConfig = {
    update: props.updateAvailable,
    cached: props.contentCached,
    reg: props.swRegistration
  }

  useEffect(() => {
    ReactGA.initialize('UA-36438118-1');
    ReactGA.send({hitType: "pageview", page: "/", title: "App"});
  })

  return (
    <StyledEngineProvider injectFirst>
      <Box className={props.classes.app}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.updateAvailable}>
          <Alert icon={false} severity="success" onClick={updateAPP}>
            Nueva versión disponible<span className={props.classes.btnUpdate}>Actualizar</span>
          </Alert>
        </Snackbar>
        <Ads />
        <BrowserRouter basename="/public3">
          <Routes>
            <Route path="/participant" element={<Participant />} />
            <Route path="/judge" element={<Judge />} />
            <Route path="/contests/:discipline/:id" element={<Contest />} />
            <Route path="/contests/:discipline" element={<ListContest />} />
            <Route path="/doma/ranking" element={<Ranking />} />
            <Route path="/morf/ranking" element={<RankingMorf />} />
            <Route path="/:discipline/ranking" element={<RankingOD />} />
            <Route path="/" element={<Home swConfig={swConfig} />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </StyledEngineProvider>
  );
}


App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App)

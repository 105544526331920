import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox';
import withStyles from '@mui/styles/withStyles';

import API from 'services/Api.js'
import ContestTypeCard from '../shared/ContestTypeCard.js'
import HomeCustom from './HomeCustom.js'
import Footer from 'components/layout/Footer.js'
import { DISCIPLINES } from 'constants/constants.js'

import HomeHeader from './HomeHeader.js'

import sharedIcon from 'assets/home/shared-icon.png'
import icon_morf from 'assets/home/icono_morfo.png'
import icon_doma from 'assets/home/icono_clasica.png'
import icon_ae from 'assets/home/icono_aescuela.png'
import icon_va from 'assets/home/icono_vaquera.png'
import icon_ama from 'assets/home/icono_amazona.png'
import icon_eng from 'assets/home/icono_enganches.png'
import icon_eqt from 'assets/home/icono_equitacion_trabajo.png'
import icon_sa from 'assets/home/icono_salto.png'
import ReactGA from "react-ga4"


const styles = theme => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  gridParent: {
    width: "100%",
    margin: 0,
    padding: "0 16px 0 16px",
    [theme.breakpoints.down('sm')]: {
      padding: "0 2px 0 2px",
    },
  },
  popup: {
    position: "sticky",
    top: "auto!important",
    bottom: 10,
    width: "100%",
    margin: "auto",
    zIndex: 100,
  },
  paper: {
    width: "98%",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    padding: 4,
    paddingLeft: 0,
  },
  sharedIcon: {
    height: 24,
  },
  arrow: {
    fontSize: 10,
    width: '3em',
    height: '3em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
});


class Home extends React.Component {
  constructor(props) {
    super(props)
    this._timeout = null
    this.state = {
      enablePopup: true,
      custom_elements: { 'UP': [], 'DOWN': [] }
    }
  }

  componentDidMount() {
    ReactGA.send({hitType: "pageview", page: "/", title: "Home"});
    this._timeout = setTimeout(
      this.setState({ enablePopup: false })
      , 10 * 1000
    )

    API.home.getCustom()
      .then(resp => {
        this.setState({ custom_elements: resp })
      })
      .catch(error => {
        console.error("Error loading custom home");
      });
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  render() {
    const { classes } = this.props
    const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())
    const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone)

    const showInstallPopup = isIos && !isInStandaloneMode && this.state.enablePopup

    const info = "Bienvenido a la web de concursos de caballos y yeguas de Pura Raza Española, donde podrá consultar y seguir en directo los resultados del calendario oficial ANCCE"
    const title1 = "DISCIPLINAS ONLINE"
    const onlineDisciplines = [
      { img: icon_morf, discipline: 'morf' },
      { img: icon_doma, discipline: 'doma' },
    ]

    const title2 = "OTRAS DISCIPLINAS"
    const otherDisciplines = [
      { img: icon_eqt, discipline: 'et' },
      { img: icon_ae, discipline: 'ae' },
      { img: icon_va, discipline: 'dv' },
      { img: icon_eng, discipline: 'en' },
      { img: icon_sa, discipline: 'sa' },
      { img: icon_ama, discipline: 'ama' },
    ]

    const addIconStyle = {
      fontSize: 42,
    }

    return (
      (<Box>
        <HomeHeader info={info} />
        <HomeCustom elements={this.state.custom_elements.UP || []} />
        <Typography className={classes.title} variant="h4">{title1}</Typography>
        <Grid
          className={classes.gridParent}
          container
          spacing={2}
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "stretch"
          }}>
          {onlineDisciplines.map((c, i) => {
            return (
              <ContestTypeCard key={i} img={c.img} discipline={c.discipline} title={DISCIPLINES[c.discipline]} alt={DISCIPLINES[c.discipline]} />
            )
          })}
        </Grid>
        <Typography className={classes.title} variant="h4">{title2}</Typography>
        <Grid
          className={classes.gridParent}
          container
          spacing={2}
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "stretch"
          }}>
          {otherDisciplines.map((c, i) => {
            return (
              <ContestTypeCard key={i} img={c.img} discipline={c.discipline} title={DISCIPLINES[c.discipline]} alt={DISCIPLINES[c.discipline]} />
            )
          })}
        </Grid>
        <HomeCustom elements={this.state.custom_elements.DOWN || []} />
        <Footer />
        { showInstallPopup &&
          <Box className={classes.popup}>
            <Paper className={classes.paper}>
              <AddBoxIcon style={addIconStyle} htmlColor="#aaaaaa" />
              <Typography>
                Para instalar la WebApp: pulsa  <img className={classes.sharedIcon} src={sharedIcon} alt="shared" />  y añade a la pantalla de inicio
                </Typography>
            </Paper>
            {<span className={classes.arrow} ref={ref => this.arrowRef = ref}></span>}
          </Box>

        }
      </Box>)
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Home)

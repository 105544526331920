import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { TableCell, TableRow } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import SpecialAwardTable from './SpecialAwardTable.js'


const styles = theme => ({
  rotate0: {
    color: "white",
    transform: 'rotate(0deg)',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    }
  },
  rotate180: {
    color: theme.palette.primary.main,
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    }
  },
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  cellCollapse: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  cellCollapseActive: {
    borderBottom: "4px solid " + theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  collapseIn: {
    overflowX: "auto",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "16px",
  },
  collapseHidden: {
    overflowX: "hidden",
    display: "none",
  },
  cell: {
    padding: 0,
  }
});


class SpecialAwardTablesCollapse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  }

  render() {
    const { classes } = this.props

    const iconRotate = clsx(classes.rotate0, {
      [classes.rotate180]: this.state.expanded,
    })

    return (
      <React.Fragment>
        <TableRow>
          <TableCell className={classes.cell} colSpan={3}>
            <div className={this.state.expanded ? classes.collapseIn : classes.collapseHidden}>
              { this.props.score_tables.map(([score_type, scores], key) => {
                return (
                    <SpecialAwardTable key={key} score_type={ score_type } scores={ scores } />
                )
              })}
            </div>
          </TableCell>
        </TableRow>

        <TableRow onClick={this.handleExpandClick}>
          <TableCell colSpan={3} className={this.state.expanded ? classes.cellCollapseActive : classes.cellCollapse } align="center">
            <ExpandMoreIcon className={iconRotate} />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

SpecialAwardTablesCollapse.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SpecialAwardTablesCollapse)

import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme.js'

import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


class Index extends PureComponent {
  state = {
    contentCached: false,
    updateAvailable: false,
    swRegistration: null,
  }

  componentDidMount() {
    const config = {
      onSuccess: this.handleSuccess,
      onUpdate: this.handleUpdate,
    }
    serviceWorker.register(config)
  }

  render() {
    const { contentCached, updateAvailable, swRegistration } = this.state
    return (
      <ThemeProvider theme={theme}>
        <App contentCached={contentCached} updateAvailable={updateAvailable} swRegistration={swRegistration} />
      </ThemeProvider>
    )
  }

  handleUpdate = (reg) => {
    this.setState({
      updateAvailable: true,
      swRegistration: reg
    })
  }

  handleSuccess = () => {
    this.setState({ contentCached: true })
  }
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Index />)

export const DISCIPLINES = {
  'ae': 'Alta Escuela',
  'doma': 'Doma Clásica',
  'vaquera': 'Doma Vaquera',
  'dv': 'Doma Vaquera',
  'eng': 'Enganches',
  'en': 'Enganches',
  'et': 'Equitación de trabajo',
  'libertad': 'Libertad',
  'morf': 'Morfológicos',
  'sa': 'Salto',
  'trc': 'TRC',
  'ama': 'Amazonas',
  'ex': 'Exhibiciones',
}

export const MONTHS = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre'
}

export const DOMA_LEVELS = {
  1: '4 Años',
  2: '5 Años',
  3: '6 Años',
  4: '7 Años',
  5: 'San Jorge',
  6: 'Gran Premio',
  7: 'Alevines',
  8: 'Infantiles',
  9: 'Juveniles 0',
  10: 'Juveniles',
  11: 'Jovenes Jinetes',
}

// Generate years ANCCE contest
const years = []
const firstYear = 1991
let currentYear = new Date().getFullYear()
while (currentYear >= firstYear) {
  years.push({value: currentYear, label: currentYear})
  currentYear--
}
export const YEARS = years

export const SPECIAL_AWARD_TYPES = {
  0: 'Votación Campeón',
  1: 'Votación desempate Campeón',
  2: 'Votación Subcampeón',
  3: 'Votación desempate Subcampeón'
}

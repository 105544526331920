export const RANKING_MORF = {
  3: {
    section_name: "S3",
    updated: "7 de Abril de 2021 a las 12:17",
    ranking: [
      {
        position: 1,
        classified: true,
        horse: "OCEANO XXV",
        code: "724015120292374",
        breeder_name: "YEGUADA ORELLANA",
        livestock_holder_name: "YEGUADA GARCIA",
        scores: [
          {
            contest_code: "2022p1",
            name: "Madrid - 20/02/2021",
            score: 23,
          },
          {
            contest_code: "2022p1",
            name: "Sevilla - 25/03/2021",
            score: 20,
          },
          {
            contest_code: "2022p1",
            name: "Barcelona - 30/03/2021",
            score: 24,
          },
        ],
        total_score: "120.900",
      },
      {
        position: 2,
        classified: false,
        horse: "OCEANO XXV",
        code: "724015120292374",
        breeder_name: "YEGUADA ORELLANA",
        livestock_holder_name: "YEGUADA GARCIA",
        scores: [
          {
            contest_code: "2022p1",
            name: "Madrid - 20/02/2021",
            score: 23,
          },
          {
            contest_code: "2022p1",
            name: "Sevilla - 25/03/2021",
            score: 20,
          },
          {
            contest_code: "2022p1",
            name: "Barcelona - 30/03/2021",
            score: 24,
          },
        ],
        total_score: "120.900",
      },
      {
        position: 3,
        classified: false,
        horse: "OCEANO XXV",
        code: "724015120292374",
        breeder_name: "YEGUADA ORELLANA",
        livestock_holder_name: "YEGUADA GARCIA",
        scores: [
          {
            contest_code: "2022p1",
            name: "Madrid - 20/02/2021",
            score: 23,
          },
          {
            contest_code: "2022p1",
            name: "Sevilla - 25/03/2021",
            score: 20,
          },
          {
            contest_code: "2022p1",
            name: "Barcelona - 30/03/2021",
            score: 24,
          },
        ],
        total_score: "120.900",
      },
    ],
  },
  4: {
    section_name: "S4",
    updated: "",
    ranking: [],
  },
};


import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@mui/styles/withStyles';

import Masonry from 'react-masonry-component'
import ParticipantImage from 'components/pages/participant/ParticipantImage.js'


const styles = theme => ({
  gridItem: {
    backgroundColor: "black",
    color: "white",
  },
  boxImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "auto",
    margin: "auto",
    padding: 0,
  },
  cell: {
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px black",
  },
  boxInfo: {
    backgroundColor: "#00000080",
    position: "absolute",
    width: 420,
    color: "white",
    bottom: 0,
    padding: "8px 0px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
    }
  },
  boxTitle: {
    //textTransform: "capitalize"
  },
  bold: {
    fontWeight: "bold",
  },
  masonry: {
    margin: "16px auto",
    [theme.breakpoints.down('md')]: {
      width: "95%!important",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%!important",
    }
  },
  participant: {
    margin: theme.spacing(1),
    width: 420,
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "8px 0px",
    }
  }
});


class Gallery extends React.Component {
  render() {
    const { classes } = this.props

    const masonryOptions = {
      transitionDuration: 0,
      fitWidth: true,
    }

    return (
      <Masonry className={classes.masonry} options={masonryOptions}>
        {this.props.participants.filter(p=>p.medias.img1).map((participant, key) => {
          return (
            <ParticipantImage key={key} className={classes.participant} img={participant.medias.img1} alt={participant.name} participant={participant} />
          )
        })}
      </Masonry>
    )
  }
}



Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Gallery)


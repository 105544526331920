import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  tableRowExpandMobile: {
    margin: 0,
    padding: 0,
    backgroundColor: "#f1f1f1",
    color: theme.palette.primary.main,
    height: 16,
    borderBottom: "4px solid white",
  },
  collapse: {
    overflowX: "auto",
  },
  collapseBox: {
    width: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: "0px 8px",
    },
  },
  subtable: {
    overflowX: "auto",
  },
  cellExpand: {
    padding: 0,
    width: 400,
  },
  cellSubtableRight: {
    padding: "0px 8px",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  cellSubtableLeft: {
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  cellSubtable: {
    padding: "0px 8px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  smallFont: {
    fontSize: 12,
  },
  total: {
    fontSize: 18,
    fontWeight: "bold",
    padding: "0px 8px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  link: {
    color: "black",
  },
});

class ParticipantSemifinalScoreCellExpand extends React.Component {
  getScore = (score) => {
    if (score && typeof score === "number") {
      return score.toFixed(3);
    }
  };

  getJudgeIdByIndex = (index, type) => {
    let id = 0;
    this.props.judges[type].forEach((j) => {
      if (j.alias.includes(String(index))) {
        id = j.id;
      }
      return "";
    });
    return id;
  };

  shouldExpand = () => {
    let participant = this.props.participant;
    let jmorf = participant.jmorf
      ? participant.jmorf.filter((j) => j > 0).length
      : 0;
    let jmov = participant.jmov
      ? participant.jmov.filter((j) => j > 0).length
      : 0;
    let jfunc = participant.jfunc
      ? participant.jfunc.filter((j) => j > 0).length
      : 0;
    let jtotal = participant.jtotal
      ? participant.jtotal.filter((j) => j > 0).length
      : 0;
    return Math.max(jmorf, jmov, jfunc, jtotal) > 0;
  };

  round2Fixed = (value) => {
    value = +value;

    if (isNaN(value)) return NaN;

    // Shift
    value = value.toString().split("e");
    value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] + 2 : 2)));

    // Shift back
    value = value.toString().split("e");
    return (+(value[0] + "e" + (value[1] ? +value[1] - 2 : -2))).toFixed(3);
  };

  render() {
    const { classes, participant } = this.props;

    return (
      <TableRow>
        <TableCell className={classes.cellExpand} colSpan={8}>
          <Collapse
            className={classes.collapse}
            in={this.props.expanded}
            timeout="auto"
            unmountOnExit
          >
            <Box className={classes.collapseBox}>
              <Table className={classes.subtable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    {participant.jtotal.map((k, j) => {
                      return (
                        <TableCell key={j} className={classes.cellSubtable}>
                          {this.props.judgesSemi &&
                            this.props.judgesSemi.length > 0 && (
                              <React.Fragment>
                                <span>{this.props.judgesSemi[j].alias}</span>
                              </React.Fragment>
                            )}
                        </TableCell>
                      );
                    })}
                    <TableCell className={classes.cellSubtableLeft}>
                      TOTAL
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    {participant.jtotal.length > 0 &&
                      participant.jtotal.map((t, i) => (
                        <TableCell className={classes.cellSubtable} key={i}>
                          {t}%
                        </TableCell>
                      ))}
                    <TableCell>
                      {this.round2Fixed(
                        participant.jtotal.reduce(
                          (points, acc) => points + acc
                        ) / participant.jtotal.length
                      )}
                      %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
}

ParticipantSemifinalScoreCellExpand.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParticipantSemifinalScoreCellExpand.defaultProps = {
  isMobile: false,
};

export default withStyles(styles)(ParticipantSemifinalScoreCellExpand);

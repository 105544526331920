import React from "react";
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';
import { Button, ButtonGroup, MenuItem, Select } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import Spinner from "components/layout/Spinner";

const styles = (theme) => ({
  btnGroupSections: {
    display: "block",
    textAlign: "left",
    borderRadius: 0,
    fontWeight: "bold",
  },
  btnSection: {
    borderRadius: 0,
  },
  btnSectionSelected: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    },
  },
  btnSectionMobile: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    border: 0,
    borderRadius: 0,
    height: 48,
    color: "white",
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
      width: "95%",
    },
  },
});

const Sections = ({ title = "...", ...props}) => {
  const { classes } = props

  if (!props.sections) {
    return null;
  }

  if (props.currentValue && !Object.keys(props.sections).includes(props.currentValue)) {
    return (
      <Spinner />
    );
  }

  return props.isMobile ? (
    <Select
      className={classes.btnSectionMobile}
      onChange={props.changeSelectSection}
      value={props.currentValue}
      displayEmpty
      IconComponent={ExpandMore}
      autoWidth={false}
    >
      <MenuItem disabled key={0} value="">
        {title}
      </MenuItem>
      {Object.entries(props.sections).map(([key, value]) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      })}
    </Select>
  ) : (
    <ButtonGroup className={classes.btnGroupSections} color="secondary">
      {Object.entries(props.sections).map(([key, value]) => {
        return (
          <Button
            key={key}
            className={key === props.currentValue ? classes.btnSectionSelected : classes.btnSection}
            onClick={() => props.changeButtonGroupSection(key)}
          >
            {value}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default withTheme(withStyles(styles)(Sections));

import React from "react";
import PropTypes from "prop-types";

import clsx from 'clsx'
import { Link } from "react-router-dom";
import { Button, TableCell, TableRow, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import withStyles from '@mui/styles/withStyles';

import multimedia from "assets/contest/multimedia.png";
import { getScoreDoma, getPositionDoma, getTotalDoma } from "utils/utils.js";
import ParticipantDressageScoreCellExpand from './ParticipantDressageScoreCellExpand.js'

const styles = (theme) => ({
  rotate0: {
    color: "white",
    transform: "rotate(0deg)",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
  },
  rotate180: {
    color: theme.palette.primary.main,
    transform: "rotate(180deg)",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
  },
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  cellPos: {
    fontWeight: "bold",
    backgroundColor: "black",
    color: "white",
    padding: 0,
    width: 32,
  },
  cellCollapse: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  cellCollapseActive: {
    borderBottom: "4px solid " + theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  link: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    padding: 0,
    whiteSpace: "break-spaces",
  },
  cellJumpLine: {
    whiteSpace: "break-spaces",
  },
  cellMobile: {
    margin: 0,
    padding: 0,
  },
  cellMobilePos: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  bold: {
    fontWeight: "bold",
  },
  multimedia: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: "4px 8px",
  },
  multimediaImg: {
    width: "32px!important",
  },
  extraWhiteCell: {
    margin: 0,
    padding: 0,
    height: 1,
  },
});

class ParticipantScoreDoma extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes, participant } = this.props;
    const iconRotate = clsx(classes.rotate0, {
      [classes.rotate180]: this.state.expanded,
    });
    const cellExpand = clsx(classes.cellMobileExpand, {
      [classes.cellMobileExpandOpen]: this.state.expanded,
    });

    return (
      <React.Fragment key={this.props.key}>
        {this.props.isMobile ? (
          <React.Fragment>
            <TableRow>
              <TableCell
                className={classes.cellMobile}
                align="center"
                colSpan={2}
              >
                <Typography
                  className={classes.cellMobilePos}
                  variant="body2"
                  noWrap={true}
                >
                  {getPositionDoma(
                    participant.pos,
                    participant.scores.state_p1,
                    participant.scores.state_p2
                  )}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              className={classes.tableRowParticipant}
              onClick={this.handleExpandClick}
            >
              <TableCell>
                <Typography variant="body2">
                  <Link
                    className={classes.link}
                    to={'/participant?code=' + participant.code}
                    onClick={this.handleClick}
                  >
                    {participant.name}
                  </Link>
                </Typography>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {participant.father} - {participant.mother}
                </Typography>
                <Typography variant="body2">J: {participant.rider}</Typography>
              </TableCell>
              <TableCell className={classes.cellMultimedia} align="center">
                {(participant.medias.video1 || participant.medias.video2) && (
                  <Button
                    className={classes.multimedia}
                    color="primary"
                    onClick={(event) => this.props.handleDialog(event, participant)}
                  >
                    <img
                      className={classes.multimediaImg}
                      src={multimedia}
                      alt="multimedia"
                    />
                  </Button>
                )}
                <Typography variant="body2">
                  P1{' '}
                  {getScoreDoma(
                    participant.scores.state_p1,
                    participant.scores.p1
                  )}
                </Typography>
                <Typography variant="body2">
                  P2{' '}
                  {getScoreDoma(
                    participant.scores.state_p2,
                    participant.scores.p2
                  )}
                </Typography>
                { this.props.hasScore3 &&
                  <Typography variant="body2">
                    P3{' '}
                    {getScoreDoma(
                      participant.scores.state_p3,
                      participant.scores.p3
                    )}
                  </Typography>
                }
                <Typography variant="body1">
                  <b>{getTotalDoma(participant.scores)}</b>
                </Typography>
              </TableCell>
            </TableRow>
            <ParticipantDressageScoreCellExpand
              contest={this.props.contest}
              judges={this.props.judges}
              section={this.props.section}
              participant={participant}
              expanded={this.state.expanded}
              scoresDefinition={this.props.scoresDefinition}
              daysDifferent={this.props.daysDifferent}
              hasScore3={this.props.hasScore3}
            />
            <TableRow onClick={this.handleExpandClick}>
              <TableCell className={cellExpand} align="center" colSpan={2}>
                <ExpandMoreIcon className={iconRotate} />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableRow
              className={classes.tableRowParticipant}
              onClick={this.handleExpandClick}
            >
              <TableCell
                classes={{ root: classes.cellRoot }}
                className={classes.cellPos}
                align="center"
              >
                {getPositionDoma(
                  participant.pos,
                  participant.scores.state_p1,
                  participant.scores.state_p2
                )}
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Link
                    className={classes.link}
                    to={'/participant?code=' + participant.code}
                    onClick={this.handleClick}
                  >
                    {participant.name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {participant.father}
                  <br />
                  {participant.mother}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {participant.rider ? participant.rider.toUpperCase() : "-"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{participant.livestock}</Typography>
                <Typography variant="body2">{participant.breeder}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  P1{' '}
                  {getScoreDoma(
                    participant.scores.state_p1,
                    participant.scores.p1
                  )}
                </Typography>
                <Typography variant="body2">
                  P2{' '}
                  {getScoreDoma(
                    participant.scores.state_p2,
                    participant.scores.p2
                  )}
                </Typography>
                { this.props.hasScore3 &&
                  <Typography variant="body2">
                    P3{' '}
                    {getScoreDoma(
                      participant.scores.state_p3,
                      participant.scores.p3
                    )}
                  </Typography>
                }
                <Typography variant="body1">
                  <b>{getTotalDoma(participant.scores)}</b>
                </Typography>
              </TableCell>
              <TableCell>
                {(participant.medias.video1 || participant.medias.video2) && (
                  <Button
                    className={classes.multimedia}
                    color="primary"
                    onClick={(event) => this.props.handleDialog(event, participant)}
                  >
                    <img
                      className={classes.multimediaImg}
                      src={multimedia}
                      alt="multimedia"
                    />
                  </Button>
                )}
              </TableCell>
              <TableCell
                className={
                  this.state.expanded
                    ? classes.cellCollapseActive
                    : classes.cellCollapse
                }
                align="center"
              >
                <ExpandMoreIcon className={iconRotate} />
              </TableCell>
            </TableRow>
            <ParticipantDressageScoreCellExpand
              contest={this.props.contest}
              judges={this.props.judges}
              section={this.props.section}
              participant={participant}
              expanded={this.state.expanded}
              scoresDefinition={this.props.scoresDefinition}
              daysDifferent={this.props.daysDifferent}
              hasScore3={this.props.hasScore3}
            />
          </React.Fragment>
        )}

        <TableRow>
          <TableCell className={classes.extraWhiteCell} colSpan={8}></TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

ParticipantScoreDoma.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParticipantScoreDoma.defaultProps = {
  isMobile: false,
  hasScore3: false,
};

export default withStyles(styles)(ParticipantScoreDoma);

import { createTheme } from '@mui/material/styles';
import yantramanav from 'typeface-yantramanav'


const defaultTheme = createTheme()

const theme = createTheme({
  palette: {
    primary: {
      //main: '#770303',
      //main: '#c60637',
      main: '#c91c36',
    },
    text: {
      secondary: '#888888',
    },
    secondary: {
      main: '#fab31e',
    },
    disabled: {
      // main: '#f6b715',
      main: '#f1f1f1',
    },
  },
  typography: {
    fontFamily: [
      'Yantramanav',
    ].join(','),
    fontSize: 16,  // default: 14px
    h1: {
      fontSize: '3.5rem',
      lineHeight: 1,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '2.5rem',
        lineHeight: 1.8,
      },
    },
    h2: {
      fontSize: '3rem',
      lineHeight: 1,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '2.5rem',
      lineHeight: 1,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '2rem',
      lineHeight: 1,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: 1,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
        fontWeight: 500,
      },
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: 1,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1rem',
        fontWeight: 500,
      },
    },
    subtitle1: {
      fontSize: '2rem',
      lineHeight: 1,
      fontWeight: 300,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    subtitle2: {
      fontSize: '1.5rem',
      lineHeight: 1,
      fontWeight: 300,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.3,
      fontWeight: 300,
      [defaultTheme.breakpoints.down('sm')]: {
        lineHeight: 1.2,
      },
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.3,
      fontWeight: 300,
      [defaultTheme.breakpoints.down('sm')]: {
        lineHeight: 1.2,
      },
    },
    overline: {
      fontSize: '0.75rem',
      lineHeight: 1,
      fontWeight: 300,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 8,
          borderBottom: 0,
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [yantramanav]
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: ({ theme }) => ({
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: 0,
                '&.Mui-disabled': {
                  border: `1px solid ${theme.palette.disabled.main}`,
                },
              }),
            }
          ]
        }
      }
    },
  },
})

export default theme

import React from 'react'
import PropTypes from 'prop-types'

import { Box,
         Button,
         Table, TableBody, TableCell, TableHead, TableRow,
         Typography } from '@mui/material'
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';

import { withNavigate } from 'components/hooks/HOC.js'
import API from 'services/Api.js'
import Footer from 'components/layout/Footer.js'
import Header from 'components/layout/Header.js'
import JudgeCard from './JudgeCard.js'
import ParticipantRow from 'components/pages/participant/ParticipantRow.js'
import Searching from 'components/pages/shared/Searching.js'
import ReactGA from "react-ga4"


const styles = theme => ({
  box: {
    borderBottom: "none",
  },
  boxModes: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: "right",
    fontFamily: "Yantramanav",
    fontSize: 16,
    fontWeight: "bold",
    padding: "4px 16px",
  },
  mode: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  year: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: 1,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
  },
  cellPos: {
    padding: 0,
  },
  table: {
    margin: 0,
  },
});


class Judge extends React.Component {
  constructor(props) {
    super(props)
    this._is_mounted = false
    this.resize = this.resize.bind(this)
    this.options = ["concursos", "fotos", "LG"]
    this.queryParams = Object.fromEntries(new URLSearchParams(this.props.location.search))
    this.state = {
      participants: [],
      amounts: {},
      sponsor: 'ANCCE COVAP',
      currentBreakpoint: "",
      currentOption: "concursos",
      limit: 50,
      offset: 0,
      max_offset: 0,
    }
  }

  componentDidMount() {
    ReactGA.send({hitType: "pageview", page: "/judge", title: "Judge"});
    this._is_mounted = true
    window.addEventListener("resize", this.resize)
    this.resize()
    this.search()
  }

  componentWillUnmount() {
    this._is_mounted = false
    window.removeEventListener("resize", this.resize)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.search()
    }
  }

  resize() {
    let res = "xs"
    if (window.innerWidth < this.props.theme.breakpoints.values["sm"]) {
      res = "xs"
    } else if (window.innerWidth < this.props.theme.breakpoints.values["md"]) {
      res = "sm"
    } else if (window.innerWidth < this.props.theme.breakpoints.values["lg"]) {
      res = "md"
    } else if (window.innerWidth < this.props.theme.breakpoints.values["xl"]) {
      res = "lg"
    } else {
      res = "xl"
    }
    if (this.state.currentBreakpoint !== res) {
      this.setState({currentBreakpoint: res})
    }
  }

  groupBy = (arr, prop) => {
    let map = new Map(Array.from(arr, obj => [obj[prop], []]))
    arr.forEach(obj => map.get(obj[prop]).push(obj))
    return map
  }

  getJudge = () => {
    if (this.state.participants.length > 0) {
      return this.state.participants[0]['judge'] || {}
    } else {
      return {}
    }
  }

  getQueryFromParams = (params) => {
    // Filters for remove "", false, null and undefined values
    let validParams = Object.entries(params).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
    return new URLSearchParams(validParams).toString()
  }

  search = () => {
    this.setState({searching: true})
    if (!this.queryParams['offset']) {
      this.queryParams['offset'] = 0
    }
    let query = this.getQueryFromParams(this.queryParams)
    API.judge.searchJudge(query)
      .then(resp => {
        let max_offset = 0
        if (resp && resp.judge.length > 0) {
          max_offset = parseInt(resp.judge[0]['amount_rows'] / 50)
        }
        this.setState({
          max_offset: max_offset,
          participants: resp.judge,
          amounts: resp.amounts,
          searching: false
        })
      })
      .catch(error => {
        alert("Error loading participants for judge with params: " + this.props.location.search)
        this.setState({searching: false})
      });
  }

  loadMore = () => {

    this.queryParams['offset'] = (this.state.offset + 1) * this.state.limit

    let query = this.getQueryFromParams(this.queryParams)
    API.judge.searchJudge(query)
      .then(resp => {
        this.setState({
          offset: this.state.offset + 1,
          participants: this.state.participants.concat(resp.judge),
          searching: false
        })
      })
      .catch(error => {
        alert("Error loading participants for judge with params: " + this.props.location.search)
        this.setState({searching: false})
      });
  }

  render() {
    const { classes } = this.props
    const participantsByYear = this.groupBy(this.state.participants, 'year')
    const heads = ["POS", "Concurso", "Sec.", "Ejemplar/Titular", "Punt.", ""]
    const isMobile = ["xs"].includes(this.state.currentBreakpoint) ? true : false
    const firstParticipant = this.state.participants.length > 0 ? this.state.participants[0] : {}

    // SEARCHING
    if (this.state.searching) {
      return (<Searching />)
    }

    return (
      <Box className={classes.box}>
        <Header />

        <JudgeCard judge={this.getJudge()} search={this.queryParams} amounts={this.state.amounts} firstParticipant={firstParticipant} />

        <Box className={classes.boxModes}>
          { Array.from(participantsByYear.keys()).length === 0 && <Typography className={classes.mode} align="left" variant="h4">No se han encontrado resultados</Typography> }

          <Table className={classes.table}>
            {Array.from(participantsByYear.keys()).map((year, index) => {
              return (
                <React.Fragment key={index}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography className={classes.year} align="left">
                          {year}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    { !isMobile &&
                      <TableRow>
                        { heads.map((value, indexH) => {
                          return (
                            <React.Fragment key={indexH}>
                              <TableCell className={value === 'POS' ? classes.cellPos : ''} align="left">
                                <Typography variant="h6">{value}</Typography>
                              </TableCell>
                            </React.Fragment>
                          )
                        })}
                      </TableRow>
                    }
                  </TableHead>
                  <TableBody>
                    {participantsByYear.get(year).map((parti, indexP) => {
                      return (
                        <React.Fragment key={indexP}>
                          <ParticipantRow key={`${index}p${indexP}`} isMobile={isMobile} participant={parti} />
                        </React.Fragment>
                      )
                    })}
                  </TableBody>
                </React.Fragment>
              )
            })}
          </Table>
        </Box>

        { this.state.max_offset > this.state.offset &&
        <Button className={classes.button} onClick={this.loadMore} color="primary" variant="contained">Cargar más</Button>
        }

        <Footer />
      </Box>
    );
  }
}

Judge.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(withNavigate(Judge)))

import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { Collapse, TableCell, TableRow } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
  rotate0: {
    color: "white",
    transform: 'rotate(0deg)',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      fontSize: 24,
    }
  },
  rotate180: {
    color: theme.palette.primary.main,
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      fontSize: 24,
    }
  },
  cell: {
    padding: "0 8px 0 8px",
  },
  contentRow: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  cellMobileExpand: {
    margin: 0,
    padding: 0,
    backgroundColor: "#f1f1f1",
    borderBottom: "4px solid " + theme.palette.primary.main,
  },
  cellMobileExpandOpen: {
    margin: 0,
    padding: 0,
    backgroundColor: "white",
    borderBottom: "4px solid " + theme.palette.primary.main,
  },
});


class RowClickAndExpand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  }

  render() {
    const { classes } = this.props

    const iconRotate = clsx(classes.rotate0, {
      [classes.rotate180]: this.state.expanded,
    })

    const cellExpand = clsx(classes.cellMobileExpand, {
      [classes.cellMobileExpandOpen]: this.state.expanded,
    })

    return (
      <React.Fragment>
        <TableRow className={classes.contentRow} onClick={this.handleExpandClick}>
          { this.props.contentRow }
        </TableRow>
        <TableRow>
          <Collapse component="td" className={classes.cell} colSpan={this.props.numRows} in={this.state.expanded} timeout="auto" unmountOnExit>
          { this.props.contentExpandRow }
          </Collapse>
        </TableRow>
        <TableRow onClick={this.handleExpandClick}>
          <TableCell className={cellExpand} align="center" colSpan={this.props.numRows}>
            <ExpandMoreIcon className={iconRotate} />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

RowClickAndExpand.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RowClickAndExpand)

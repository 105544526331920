import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

import footer from '../../assets/footer/footer@3x.png'
import packageJson from '../../../package.json';


const styles = theme => ({
  box: {
    position: "relative",
  },
  footer: {
    height: 300,
    objectFit: "cover",
    width: "100%",
    marginBottom: -48,
  },
  textFooter: {
    position: "absolute",
    width: "95%",
    bottom: theme.spacing(2),
    left: "50%",
    transform: "translate(-50%)",
    color: "white",
  },
  version: {
    position: "absolute",
    color: "white",
    bottom: "-40px",
    left: "4px",
  }
});


class Footer extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      (<Box className={classes.box}>
        <img className={classes.footer} src={footer} alt="footer" />
        <Typography className={classes.textFooter} variant="body2" sx={{
          display: "inline"
        }}>
          ANCCE Asociación Nacional de Criadores de Caballos de Pura Raza Española
          <br />
          © ANCCE {(new Date()).getFullYear()}
        </Typography>
        <Typography className={classes.version} variant="body2">
          v{packageJson.version}
        </Typography>
      </Box>)
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)

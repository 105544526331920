import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos, Close } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import Video from 'components/pages/contest/Video.js';

const styles = (theme) => ({
  dialog: {
    margin: -32,
  },
  title: {
    padding: '8px 32px 8px 16px',
    color: 'white',
    backgroundColor: 'black',
  },
  content: {
    padding: '0px 16px',
    minWidth: 560,
    [theme.breakpoints.down('sm')]: {
      minWidth: 285,
    },
    minHeigth: 100,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'white',
    padding: 4,
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: 'right',
    fontFamily: 'Yantramanav',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '4px 16px',
  },
});

class DialogMultimedia extends React.PureComponent {
  initialState;

  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      desc: this.props.video1_desc,
      video1: this.props.video1,
      previous: props.previous,
      next: props.next,
      render: false,
      tablesDefinitions: props.tablesDefinitions,
      orderedList: props.orderedList,
    };
    this.initialState = this.state;
  }

  handleClose = () => {
    this.setState(this.initialState);
    this.props.onClose();
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes } = this.props;
    const hasPrev = this.canLoadPreviousParticipant();
    const hasNext = this.canLoadNextParticipant();

    return (
      <Dialog
          className={classes.dialog}
          onClose={this.props.onClose}
          onClick={this.props.onClick}
          scroll={this.props.scroll}
          open={this.props.open}
        >
        <DialogTitle className={classes.title}>
          {this.state.title || this.props.title}

          <IconButton className={classes.closeButton} onClick={this.handleClose} size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={1} gap={1}>
            <IconButton
              variant="outlined"
              size="small"
              onClick={() => this.loadPreviousParticipant()}
              disabled={!hasPrev}
            >
              <ArrowBackIosNew color={hasPrev ? "secondary" : "disabled"} />
            </IconButton>
            <IconButton
              variant="outlined"
              size="small"
              onClick={() => this.loadNextParticipant()}
              disabled={!hasNext}
            >
              <ArrowForwardIos color={hasNext ? "secondary" : "disabled"} />
            </IconButton>
          </Box>
          <div>
            <Typography key="tv1" mt={1} mb={1} variant="h6">
              {this.state.desc || this.props.video1_desc}
            </Typography>

            {(this.state.video1 || this.props.video1) ? (
                <Video
                  key={this.state.video1 ? this.state.video1 : this.props.video1}
                  source={
                    this.state.video1 ? this.state.video1 : this.props.video1
                  }
                  code={this.props.contest_code}
                />
              ) : (
                <p>No hay vídeo disponible</p>
              )
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={this.handleClose}
            color="primary"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  canLoadPreviousParticipant() {
    return (
      this.props.orderedList &&
      this.props.orderedList.findIndex((ol) =>
        this.state.video1
          ? ol.media === this.state.video1
          : ol.media === this.props.video1
      ) !== 0
    );
  }

  canLoadNextParticipant() {
    return (
      this.props.orderedList &&
      this.props.orderedList.findIndex((ol) =>
        this.state.video1
          ? ol.media === this.state.video1
          : ol.media === this.props.video1
      ) <
        this.props.orderedList.length - 1
    );
  }

  loadNextParticipant() {
    let title, video1, desc;

    const i = this.props.orderedList.findIndex((ol) =>
      this.state.video1
        ? ol.media === this.state.video1
        : ol.media === this.props.video1
    );

    const next =
      this.props.orderedList[i < this.props.orderedList.length ? i + 1 : i];

    const previous = this.props.orderedList[i > 0 ? i - 1 : i];

    video1 = next.media;
    this.props.tablesDefinitions.forEach((tdf) => {
      tdf.rows.forEach((row) => {
        if (row.code === next.name) {
          title = tdf.title;
          desc = row.name;
        }
      });
    });

    this.setState({
      ...this.state,
      title,
      desc,
      video1,
      previous,
      next,
      render: true,
    });
  }

  loadPreviousParticipant() {
    const i = this.props.orderedList.findIndex((ol) =>
      this.state.video1
        ? ol.media === this.state.video1
        : ol.media === this.props.video1
    );
    const next = this.props.orderedList[i];
    const previous = this.props.orderedList[i > 0 ? i - 1 : i];

    let title, video1, desc;
    video1 = previous.media;
    this.props.tablesDefinitions.forEach((tdf) => {
      tdf.rows.forEach((row) => {
        if (row.code === previous.name) {
          title = tdf.title;
          desc = row.name;
        }
      });
    });

    this.setState({
      ...this.state,
      title,
      desc,
      video1,
      previous,
      next,
      render: true,
    });
  }
}

DialogMultimedia.propTypes = {
  classes: PropTypes.object.isRequired,
};

DialogMultimedia.defaultProps = {
  scroll: 'body',
};

export default withStyles(styles)(DialogMultimedia);

import React from 'react'
import PropTypes from 'prop-types'

import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

import { Link } from "react-router-dom"

import API from 'services/Api.js'
import { DOMA_LEVELS } from 'constants/constants.js'


const styles = theme => ({
  box: {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  table: {
    width: "auto",
  },
  link: {
    color: "black",
    textDecoration: "underline",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  header: {
    height: 54,
  },
  headerPos: {
    fontWeight: "bold",
    fontSize: 18,
  },
  headerBreeder: {
    fontWeight: "bold",
    fontSize: 18,
  },
  headerScore: {
    textAlign: "right",
    fontWeight: "bold",
    fontSize: 18,
  },
  rowParticipantHeader: {
    backgroundColor: "black",
    textTransform: "uppercase",
  },
  rowParticipantBody: {
    backgroundColor: "#f1f1f1"
  },
  cellParticipantHeader: {
    color: "white",
    fontWeight: "bold",
  },
  cellParticipant: {
    padding: "10px 8px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
});


class ContestLevelBestBreeder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      breeders: [],
      searching: false
    }
  }

  componentDidMount() {
    this.setState({searching: true})
    API.dressage.bestBreeder(this.props.contest_code)
      .then(resp => {
        this.setState({breeders: resp.breeders, searching: false})
      })
      .catch(error => {
        console.error("Error loading breeders: " + this.props.contest_code)
        this.setState({searching: true})
      });
  }


  render() {
    const { classes } = this.props

    return (
      <Box className={classes.box}>
        <Table className={classes.table}>
          <TableBody>

            { this.state.breeders.map((breeder, key) => {
              // Breeder header
              return (
                <React.Fragment key={key}>
                  <TableRow className={classes.header}>
                    <TableCell className={classes.headerPos}>
                      { key + 1 }º
                    </TableCell>
                    <TableCell className={classes.headerBreeder} colSpan={6}>
                      { breeder.name }
                    </TableCell>
                    <TableCell className={classes.headerScore}>
                      { breeder.scores }
                    </TableCell>
                  </TableRow>

                  { /* Participants table header */ }
                  <TableRow className={classes.rowParticipantHeader}>
                    <TableCell className={classes.cellParticipantHeader}>
                      Ejemplar
                    </TableCell>
                    <TableCell className={classes.cellParticipantHeader}>
                      Jinete
                    </TableCell>
                    <TableCell className={classes.cellParticipantHeader}>
                      Propietario
                    </TableCell>
                    <TableCell className={classes.cellParticipantHeader}>
                      Total
                    </TableCell>
                    <TableCell className={classes.cellParticipantHeader}>
                      Nivel
                    </TableCell>
                    <TableCell className={`${classes.cellParticipantHeader} ${classes.noWrap}`}>
                      P1 / Punt.
                    </TableCell>
                    <TableCell className={`${classes.cellParticipantHeader} ${classes.noWrap}`}>
                      P2 / Punt.
                    </TableCell>
                    <TableCell className={classes.cellParticipantHeader}>
                      Puntuación
                    </TableCell>
                  </TableRow>
                  { breeder.participants.map((participant, keyp) => {
                    // Participants table body
                    return (
                      <TableRow key={keyp} className={classes.rowParticipantBody}>
                        <TableCell className={classes.cellParticipant}>
                          <Link className={classes.link} to={ "/participant?code=" + participant.code }>
                            { participant.name}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.cellParticipant}>
                          { participant.rider }
                        </TableCell>
                        <TableCell className={classes.cellParticipant}>
                          { participant.livestock }
                        </TableCell>
                        <TableCell className={`${classes.cellParticipant} ${classes.noWrap}`}>
                          { participant.res_total } ({ participant.total_comp })
                        </TableCell>
                        <TableCell className={classes.cellParticipant}>
                          { DOMA_LEVELS[participant.level] }
                        </TableCell>
                        <TableCell className={`${classes.cellParticipant} ${classes.noWrap}`}>
                          { participant.p1 } ({ participant.p1_comp }) <br/>
                          { participant.p1_punt }
                        </TableCell>
                        <TableCell className={`${classes.cellParticipant} ${classes.noWrap}`}>
                          { participant.p2 } ({ participant.p2_comp }) <br/>
                          { participant.p2_punt }
                        </TableCell>
                        <TableCell className={classes.cellParticipant} align="right">
                          { participant.pmg_total }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

ContestLevelBestBreeder.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContestLevelBestBreeder)

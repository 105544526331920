import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import Grid from '@mui/material/Grid2';
import { Box, Divider, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import HomeIcon from '@mui/icons-material/Home'

import { withNavigate } from 'components/hooks/HOC.js'
import logo from '../../assets/header/logo.svg'


const styles = theme => ({
  logo: {
    width: 160,
    height: "auto",
    margin: 32,
  },
  divider: {
    height: 16,
    background: theme.palette.primary.main,
    marginBottom: 16,
  },
  child1: {
    textDecoration: "none",
  },
  childLast: {
    paddingRight: 8,
    paddingLeft: 8,
    marginLeft: "auto",
    cursor: "pointer",
  },
  textHome: {
    color: "white",
  },
  bar: {
    zIndex: 1000,
    width: "100%",
    top: 0,
    position: "fixed",
    transition: "top 0.5s",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    background: theme.palette.primary.main,
    padding: "2px 8px",
  },
  barHide: {
    top: -40,
  },
  paper: {
  },
  info: {
    marginLeft: theme.spacing(22),
    marginRight: theme.spacing(22),
    textShadow: "0 2px 2px rgba(0, 0, 0, 0.5)",
    textTransform: "uppercase",
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
});


class Header extends React.Component {

  goBack = () => {
    this.props.navigate(-1)
  }

  render() {
    const { classes, info, mb } = this.props
    const isMobile = window.innerWidth < 960  // 960px is md

    return (
      (<Box sx={{
        mb: mb
      }}>
        <Box className={isMobile ? classes.bar : `${classes.bar} ${classes.barHide}`}>
          <Link to="/" className={classes.child1}>
            <HomeIcon htmlColor="#ffffff"/>
          </Link>
          <Link to="/" className={classes.child1}>
            <Typography className={classes.textHome} variant="h6">
              Inicio
            </Typography>
          </Link>
          <Box className={classes.childLast} onClick={this.goBack}>
            <ArrowBackIosIcon htmlColor="#ffffff"/>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Grid
          className={classes.paper}
          container
          direction="column"
          sx={{
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <Link to="/">
            <img className={classes.logo} src={logo} alt="logo" />
          </Link>
          { this.props.info !== null &&
            <Box
              className={classes.info}
              sx={{
                mt: 4,
                mb: 4
              }}>
              <Typography variant="h3">{ info }</Typography>
            </Box>
          }
        </Grid>
      </Box>)
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

Header.defaultProps = {
  info: null,
  mb: 2,
}

export default withStyles(styles)(withNavigate(Header))

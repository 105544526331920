import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { TableCell, TableRow, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  tableRowParticipantClassified: {
    backgroundColor: "#fab31e44",
    height: 48,
  },
  cellPos: {
    fontWeight: "bold",
    backgroundColor: "black",
    color: "white",
    padding: 0,
    width: 32,
  },
  cellPosClassified: {
    fontWeight: "bold",
    backgroundColor: "#fab31e",
    color: "white",
    padding: 0,
    width: 32,
  },
  cellCollapse: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  cellCollapseActive: {
    borderBottom: "4px solid " + theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  link: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    padding: 0,
    whiteSpace: "break-spaces",
  },
  cellJumpLine: {
    whiteSpace: "break-spaces",
  },
  cellMobile: {
    margin: 0,
    padding: 0,
  },
  cellMobileClassified: {
    margin: 0,
    padding: 0,
    backgroundColor: "#fab31e",
  },
  cellMobilePos: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  cellMobilePosClassified: {
    backgroundColor: "#fab31e",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  bold: {
    fontWeight: "bold",
  },
  multimedia: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: "4px 8px",
  },
  multimediaImg: {
    width: "32px!important",
  },
  extraWhiteCell: {
    margin: 0,
    padding: 0,
    height: 1,
  },
});

class ParticipantScoreRanking extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      open: false,
    };
  }

  handleDialog = (e) => {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  handleClose = (e) => {
    this.setState({ open: false });
  };

  render() {
    const { classes, participant } = this.props;

    return (
      <React.Fragment>
        {this.props.isMobile ? (
          <React.Fragment>
            <TableRow>
              <TableCell
                className={
                  participant.classified
                    ? classes.cellMobileClassified
                    : classes.cellMobile
                }
                align="center"
                colSpan={2}
              >
                <Typography
                  className={
                    participant.classified
                      ? classes.cellMobilePosClassified
                      : classes.cellMobilePos
                  }
                  variant="body2"
                  noWrap={true}
                >
                  {participant.position}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={
                participant.classified
                  ? classes.tableRowParticipantClassified
                  : classes.tableRowParticipant
            }>
              <TableCell>
                <Typography variant="body2">
                  <Link
                    className={classes.link}
                    to={'/participant?code=' + participant.code}
                    onClick={this.handleClick}
                  >
                    {participant.horse}
                  </Link>
                </Typography>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {participant.father} - {participant.mother}
                </Typography>
                <Typography variant="body2">J: {participant.rider}</Typography>
              </TableCell>
              <TableCell className={classes.cellMultimedia} align="center">
                {participant.scores[0] && (
                  <Typography variant="body2">
                    P1 {participant.scores[0]}
                  </Typography>
                )}
                {participant.scores[1] && (
                  <Typography variant="body2">
                    P2 {participant.scores[1]}
                  </Typography>
                )}
                {participant.scores[2] && (
                  <Typography variant="body2">
                    P3 {participant.scores[2]}
                  </Typography>
                )}
                <Typography>Total {participant.total_score}</Typography>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableRow
              className={
                participant.classified
                  ? classes.tableRowParticipantClassified
                  : classes.tableRowParticipant
              }
            >
              <TableCell
                classes={{ root: classes.cellRoot }}
                className={
                  participant.classified
                    ? classes.cellPosClassified
                    : classes.cellPos
                }
                align="center"
              >
                {participant.position}
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Link
                    className={classes.link}
                    to={'/participant?code=' + participant.code}
                    onClick={this.handleClick}
                  >
                    {participant.horse}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {participant.father}
                  <br />
                  {participant.mother}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {participant.rider.toUpperCase()}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{participant.livestock}</Typography>
                <Typography variant="body2">{participant.breeder}</Typography>
              </TableCell>
              <TableCell>
                {participant.scores[0] && (
                  <Typography variant="body2">
                    P1 {participant.scores[0]}
                  </Typography>
                )}
                {participant.scores[1] && (
                  <Typography variant="body2">
                    P2 {participant.scores[1]}
                  </Typography>
                )}
                {participant.scores[2] && (
                  <Typography variant="body2">
                    P3 {participant.scores[2]}
                  </Typography>
                )}
              </TableCell>
              <TableCell>{participant.total_score}</TableCell>
            </TableRow>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ParticipantScoreRanking.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParticipantScoreRanking.defaultProps = {
  isMobile: false,
};

export default withStyles(styles)(ParticipantScoreRanking);

export const getPETables = (regulation) => {
  const table = [
    {
      title: "Campeones Jóvenes del Concurso",
      head: ["Premio", "Campeón", "Subcampeón"],
      rows: [
        { code: "CCHJ", name: "Hembra" },
        { code: "CCMJ", name: "Macho" },
      ],
    },
    {
      title: "Campeones Adultos del Concurso",
      head: ["Premio", "Campeón", "Subcampeón"],
      rows: [
        { code: "CCH", name: "Hembra" },
        { code: "CCM", name: "Macho" },
      ],
    },
    {
      title: "Campeón de Funcionalidad",
      head: ["Premio", "Campeón", "Subcampeón"],
      rows: [{ code: "CF", name: "Adulto" }],
    },
    {
      title: "Mejor Ganadería",
      head: ["Premio", "Mejor", "Segundo mejor"],
      rows: [
        { code: "MG", name: "Criador" },
        { code: "ME", name: "Expositor" },
      ],
    },
  ];

  if (regulation === "2016") {
    table.unshift({
      title: "Mejores Movimientos del Concurso",
      head: ["Premio", "Campeón", "Subcampeón"],
      rows: [{ code: "CMM", name: "Todos" }],
    });
  } else if (regulation >= "2020") {
    table.unshift(
      {
        title: "Campeones Jóvenes de Mejores Movimientos del Concurso",
        head: ["Premio", "Campeón", "Subcampeón"],
        rows: [{ code: "CMMJS", name: "Jóven" }],
      },
      {
        title: "Campeones Adultos de Mejores Movimientos del Concurso",
        head: ["Premio", "Campeón"],
        rows: [
          { code: "CMMH", name: "Hembra" },
          { code: "CMMM", name: "Macho" },
        ],
      }
    );
  } else {
    table.unshift({
      title: "Mejores Movimientos del Concurso",
      head: ["Premio", "Campeón"],
      rows: [
        { code: "CMMJ", name: "Jóven" },
        { code: "CMM", name: "Adulto" },
      ],
    });
  }
  return table;
}

import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { Link } from "react-router-dom"
import { Button,
         TableCell, TableRow,
         Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import withStyles from '@mui/styles/withStyles';

import multimedia from 'assets/contest/multimedia.png'
import ParticipantScoreCellExpand from './ParticipantScoreCellExpand.js'
import ParticipantSemifinalScoreCellExpand from "./ParticipantSemifinalScoreCellExpand.js";
import { getPosition, getScores } from "utils/utils.js";

const styles = (theme) => ({
  rotate0: {
    color: "white",
    transform: "rotate(0deg)",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
  },
  rotate180: {
    color: theme.palette.primary.main,
    transform: "rotate(180deg)",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
  },
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  cellPos: {
    fontWeight: "bold",
    backgroundColor: "black",
    color: "white",
    padding: 0,
    width: 32,
  },
  cellCollapse: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  cellCollapseActive: {
    borderBottom: "4px solid " + theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  link: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    padding: 0,
    whiteSpace: "break-spaces",
  },
  cellJumpLine: {
    whiteSpace: "break-spaces",
  },
  cellMultimedia: {
    display: "flex",
    minHeight: "5em",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
  },
  cellMobile: {
    margin: 0,
    padding: 0,
  },
  cellMobileExpand: {
    margin: 0,
    padding: 0,
    backgroundColor: "#f1f1f1",
    borderBottom: "4px solid " + theme.palette.primary.main,
  },
  cellMobileExpandOpen: {
    margin: 0,
    padding: 0,
    backgroundColor: "white",
    borderBottom: "4px solid " + theme.palette.primary.main,
  },
  cellMobilePos: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  bold: {
    fontWeight: "bold",
  },
  multimedia: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: "4px 8px",
  },
  multimediaImg: {
    width: "32px!important",
  },
  extraWhiteCell: {
    margin: 0,
    padding: 0,
    height: 1,
  },
});

class ParticipantScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  getScore = (score) => {
    if (score && typeof score === "number") {
      return score.toFixed(3);
    }
  };

  parseRepeat = (str) => {
    if (!str) return "";
    let split = str.split(";");
    if (new Set(split).size === 1) {
      return split[0];
    } else {
      return split.join("\n");
    }
  };

  checkMedias = (medias) => {
    return Object.entries(medias).filter((m) => m[1]).length > 0;
  };

  round2Fixed = (value) => {
    value = +value;

    if (isNaN(value)) return NaN;

    // Shift
    value = value.toString().split("e");
    value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] + 2 : 2)));

    // Shift back
    value = value.toString().split("e");
    return (+(value[0] + "e" + (value[1] ? +value[1] - 2 : -2))).toFixed(3);
  }

  render() {
    const { classes, participant } = this.props;
    const iconRotate = clsx(classes.rotate0, {
      [classes.rotate180]: this.state.expanded,
    });
    const cellExpand = clsx(classes.cellMobileExpand, {
      [classes.cellMobileExpandOpen]: this.state.expanded,
    });

    const parseScores = getScores(participant.scores, participant.type, [
      "total",
    ]);
    const existMedias = this.checkMedias(participant.medias);

    return (
      (<React.Fragment>
        {this.props.isMobile ? (
          <React.Fragment>
            <TableRow>
              <TableCell
                className={classes.cellMobile}
                align="center"
                colSpan={2}
              >
                <Typography
                  className={classes.cellMobilePos}
                  variant="body2"
                  noWrap={true}
                >
                  {this.props.round === "semifinales"
                    ? "-"
                    : getPosition(
                        participant.order_total,
                        participant.scores,
                        participant.type
                      )}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              className={classes.tableRowParticipant}
              onClick={this.handleExpandClick}
            >
              <TableCell>
                <Typography variant="body2" noWrap={true} sx={{
                  display: "inline"
                }}>
                  {participant.number}
                </Typography>
                <Typography variant="body2">
                  {participant.name.map((parti, i) => {
                    return (
                      <Link
                        key={i}
                        className={classes.link}
                        to={"/participant?code=" + participant.code[i]}
                        onClick={this.handleClick}
                      >
                        {parti + "\n"}
                      </Link>
                    );
                  })}
                </Typography>
                <Typography className={classes.cellJumpLine} variant="body2">
                  T: {this.parseRepeat(participant.livestock)}
                </Typography>
                <Typography
                  className={classes.cellJumpLine}
                  variant="body2"
                  sx={{
                    color: "textSecondary"
                  }}
                >
                  C: {this.parseRepeat(participant.breeder)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellMultimedia} align="center">
                {existMedias && (
                  <Button
                    className={classes.multimedia}
                    color="primary"
                    onClick={(event) => this.props.handleDialog(event, participant)}
                  >
                    <img
                      className={classes.multimediaImg}
                      src={multimedia}
                      alt="multimedia"
                    />
                  </Button>
                )}
                <Typography className={classes.bold} variant="body2">
                  {participant.order_mov === 1 &&
                    participant.scores.mov > 0 &&
                    "M.M."}
                </Typography>
                <Typography className={classes.bold} variant="body2">
                  {participant.order_func === 1 &&
                    participant.scores.func > 0 &&
                    participant.number === this.props.mf &&
                    "M.F."}
                </Typography>
                <Typography className={classes.bold} variant="body1">
                  {this.props.round === "semifinales"
                    ? this.round2Fixed(
                        participant.jtotal.reduce(
                          (points, acc) => points + acc
                        ) / participant.jtotal.length
                      )
                    : parseScores["total"]}
                </Typography>
              </TableCell>
            </TableRow>
            {this.props.round === "semifinales" ? (
              <ParticipantSemifinalScoreCellExpand
                key={participant.code}
                contest={this.props.contest}
                judges={this.props.judges}
                judgesSemi={this.props.jsemi}
                section={this.props.section}
                participant={participant}
                expanded={this.state.expanded}
              />
            ) : (
              <ParticipantScoreCellExpand
                contest={this.props.contest}
                judges={this.props.judges}
                section={this.props.section}
                participant={participant}
                expanded={this.state.expanded}
              />
            )}
            <TableRow onClick={this.handleExpandClick}>
              <TableCell className={cellExpand} align="center" colSpan={2}>
                <ExpandMoreIcon className={iconRotate} />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableRow
              className={classes.tableRowParticipant}
              onClick={this.handleExpandClick}
            >
              <TableCell
                classes={{ root: classes.cellRoot }}
                className={classes.cellPos}
                align="center"
              >
                {" "}
                {/* TODO: mirar el tema de las classes root, puede ser interesante */}
                {this.props.round === "semifinales"
                  ? "-"
                  : getPosition(
                      participant.order_total,
                      participant.scores,
                      participant.type
                    )}
              </TableCell>
              <TableCell>
                <Typography variant="body2" noWrap={true}>
                  {participant.number}
                  {existMedias && (
                    <Button
                      className={classes.multimedia}
                      color="primary"
                      onClick={(event) => this.props.handleDialog(event, participant)}
                    >
                      <img
                        className={classes.multimediaImg}
                        src={multimedia}
                        alt="multimedia"
                      />
                    </Button>
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {participant.name.map((parti, i) => {
                    return (
                      <Link
                        key={i}
                        className={classes.link}
                        to={"/participant?code=" + participant.code[i]}
                        onClick={this.handleClick}
                      >
                        {parti + "\n"}
                      </Link>
                    );
                  })}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {this.parseRepeat(participant.livestock)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={classes.cellJumpLine}
                  variant="body2"
                  sx={{
                    color: "textSecondary"
                  }}
                >
                  {this.parseRepeat(participant.breeder)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold} variant="body2">
                  {this.props.round === "semifinales"
                    ? this.round2Fixed(
                        participant.jtotal.reduce(
                          (points, acc) => points + acc
                        ) / participant.jtotal.length
                      ) + "%"
                    : parseScores["total"]}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold} variant="body2">
                  {participant.order_mov === 1 &&
                    participant.scores.mov > 0 &&
                    "M.M."}
                </Typography>
                <Typography className={classes.bold} variant="body2">
                  {participant.order_func === 1 &&
                    participant.scores.func > 0 &&
                    participant.number === this.props.mf &&
                    "M.F."}
                </Typography>
              </TableCell>
              <TableCell
                className={
                  this.state.expanded
                    ? classes.cellCollapseActive
                    : classes.cellCollapse
                }
                align="center"
              >
                <ExpandMoreIcon className={iconRotate} />
              </TableCell>
            </TableRow>
            {this.props.round === "semifinales" ? (
              <ParticipantSemifinalScoreCellExpand
                key={participant.code}
                contest={this.props.contest}
                judges={this.props.judges}
                judgesSemi={this.props.jsemi}
                section={this.props.section}
                participant={participant}
                expanded={this.state.expanded}
              />
            ) : (
              <ParticipantScoreCellExpand
                contest={this.props.contest}
                judges={this.props.judges}
                section={this.props.section}
                participant={participant}
                expanded={this.state.expanded}
              />
            )}
          </React.Fragment>
        )}
        <TableRow>
          <TableCell className={classes.extraWhiteCell} colSpan={8}></TableCell>
        </TableRow>
      </React.Fragment>)
    );
  }
}

ParticipantScore.propTypes = {
  classes: PropTypes.object.isRequired,
}

ParticipantScore.defaultProps = {
  isMobile: false,
}

export default withStyles(styles)(ParticipantScore)

import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import { Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

import esc_spain from 'assets/participant/esc_spain.png'
import esc_andalucia from 'assets/participant/esc_andalucia.png'


const styles = theme => ({
  link: {
    textDecoration: "none",
  },
  red: {
    color: "#c91c36",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "right",
  },
  green: {
    color: "#039303",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "right",
  },
  grey: {
    color: "grey"
  },
  imgEsc: {
    paddingLeft: 2,
  },
});


class ParticipantCockade extends React.Component {
  render() {
    const { classes, champion, runner_up } = this.props

    if (!champion && !runner_up) {
      return (<span></span>)
    }

    return (
        <Link className={classes.link} to={ `/contests/morf/${this.props.contest_code}/?section=pe` }>
          {champion[0] !== null && champion.filter(code=>['C','F','M'].includes(code.charAt(1))).map((code, i) => {
            let letter = code.charAt(1)
            return (
              <Typography key={i} className={classes.red} noWrap>
                {letter}
                <img className={classes.imgEsc} src={esc_spain} alt="pe spain" />
              </Typography>
            )
          })}

          {runner_up[0] !== null && runner_up.filter(code=>['C','F','M'].includes(code.charAt(1))).map((code, i) => {
            let letter = code.charAt(1)
            return (
              <Typography key={i} className={classes.green}>
                {letter}
                <img className={classes.imgEsc} src={esc_andalucia} alt="pe spain" />
              </Typography>
            )
          })}
        </Link>
    )
  }
}

ParticipantCockade.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantCockade)


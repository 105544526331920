import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withNavigate = (Component) => {
    return (props) => {
        const navigate = useNavigate();
        const params = useParams();
        const location = useLocation();
        return <Component {...props} navigate={navigate} params={params} location={location} />;
    };
}

import React from 'react'
import PropTypes from 'prop-types'

import { Button, Paper } from '@mui/material'
import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
  paper: {
    marginTop: 32,
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: "right",
    fontFamily: "Yantramanav",
    fontSize: 16,
    fontWeight: "bold",
    padding: "4px 16px",
  },
});


class HomeCustom extends React.Component {

  render() {
    const { classes } = this.props

    if (!this.props.elements) return null

    return (
      <Paper className={classes.paper} elevation={2}>
        { this.props.elements.map((element, key) => {
          return (
            <React.Fragment key={key}>
              <Button
                className={classes.button}
                href={ element.link }
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                variant="contained"
              >
                { element.text }
              </Button>
            </React.Fragment>
          )
        })}
      </Paper>
    )
  }
}

HomeCustom.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HomeCustom)

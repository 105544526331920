import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ReactGA from "react-ga4"
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';
import { useLocation, useNavigate } from "react-router-dom"

import API from "services/Api";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import { IDLE, LOADING } from "constants/page-state";
import Spinner from "components/layout/Spinner";
import Sections from "components/layout/Sections";
import ParticipantScoreRankingMorf from "./ParticipantScoreRankingMorf";
import useIsMobile from "components/hooks/hooks"

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  main: {
    minHeight: "30vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sectionSelect: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  lastUpdated: {
    textAlign: "left",
  },
  table: {
    width: "80%",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },
  cellPos: {
    padding: 0,
    maxWidth: 32,
  },
  rankingContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
});

function transformClassification(key) {
  if (key === "E") {
    return "General"
  }
  if (key === "N") {
    return "Nacional"
  }
  if (key === "I") {
    return "Internacional"
  }
}

const RankingMorf = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(LOADING);

  const [years, setYears] = useState({});
  const [classifications, setClassifications] = useState([]);
  const [sections, setSections] = useState([]);
  const [ranking, setRanking] = useState({ranking: []});

  const [data, setData] = useState({});

  const { pathname, search } = useLocation();
  const [queryParams, setQueryParams] = useState({
    year: null,
    classification: null,
    section: null,
  })
  const { isMobile } = useIsMobile();

  useEffect(() => {
    ReactGA.send({hitType: "pageview", page: "/ranking/morf", title: "RankingMorf"});
    setPageState(LOADING)
    API.rankingMorf
      .getRanking(queryParams.year, queryParams.classification, queryParams.section)
      .then((res) => {
        setData(res)
        setPageState(IDLE)
      })
      .catch((error) => {
        console.error("Error loading api ranking morf", error);
      });
  }, [queryParams])

  useEffect(() => {
    if (search) {
      setQueryParams(Object.fromEntries(new URLSearchParams(search)));
    }
  }, [search])

  useEffect(() => {
    setYears(Object.keys(data).reduce((obj, item) => ({...obj, [item]: item}), {}));
    if (queryParams.year && data[queryParams.year]) {
      const _classifications = data[queryParams.year];
      setClassifications(
        Object.keys(_classifications).reverse().reduce((obj, item) => (
          {...obj, [item]: transformClassification(item)}
        ), {})
      );

      if (queryParams.classification && _classifications[queryParams.classification]) {
        const _sections = _classifications[queryParams.classification];
        setSections(
          Object.entries(_sections).reduce((obj, item) => (
            {...obj, [item[0]]: item[1].section_name}
          ), {})
        );

        if (queryParams.section && _sections[queryParams.section]) {
          setRanking(_sections[queryParams.section])
        } else {
          setRanking({ranking: []});
        }
      } else {
        setSections([]);
        setRanking({ranking: []});
      }
    }
  }, [data, queryParams])

  const changeSelectSection = (key, type) => {
    let query = {};
    if (type === "year") {
      query["year"] = key;
      let _classifications = Object.keys(data[key]);
      if (_classifications.length === 1) {
        query["classification"] = _classifications[0];
      }
    } else if (type === "classification") {
      query = {
        year: queryParams.year,
        classification: key,
      }
    } else if (type === "section") {
      query = {
        year: queryParams.year,
        classification: queryParams.classification,
        section: key,
      }
    }

    navigate(`${pathname}?${(new URLSearchParams(query)).toString()}`, { replace: true });
  }

  return (
      <Box>
        <Header></Header>
        <Box className={classes.main}>
          <Typography className={classes.title} variant="h4">
            Clasificados SICAB
          </Typography>

          {Object.keys(years).length > 0 && (
            <Box className={classes.sectionSelect}>
              <Sections
                title="Seleccione año"
                isMobile={isMobile}
                sections={years}
                currentValue={queryParams.year || ""}
                changeSelectSection={(event) => changeSelectSection(event.target.value, "year")}
                changeButtonGroupSection={(index) => changeSelectSection(index, "year")}
              />
            </Box>
          )}

          {Object.keys(classifications).length > 0 && (
            <Box className={classes.sectionSelect}>
              <Sections
                title="Seleccione clasificación"
                isMobile={isMobile}
                sections={classifications}
                currentValue={queryParams.classification || ""}
                changeSelectSection={(event) => changeSelectSection(event.target.value, "classification")}
                changeButtonGroupSection={(index) => changeSelectSection(index, "classification")}
              />
            </Box>
          )}

          {Object.keys(sections).length > 0 && (
            <Box className={classes.sectionSelect}>
              <Sections
                title="Seleccione sección"
                isMobile={isMobile}
                sections={sections}
                currentValue={queryParams.section || ""}
                changeSelectSection={(event) => changeSelectSection(event.target.value, "section")}
                changeButtonGroupSection={(index) => changeSelectSection(index, "section")}
              />
            </Box>
          )}

          { pageState === LOADING && <Spinner /> }

          {ranking && ranking.ranking.length > 0 && (
            <div className={classes.rankingContainer}>
              <Typography className={classes.lastUpdated} variant="body1">
                Último ranking publicado: { ranking.updated }
              </Typography>
              <Table className={classes.table}>
                {!isMobile && (
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cellPos} align="center">
                        POS.
                      </TableCell>
                      <TableCell>EJEMPLAR</TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <b>TITULAR</b>
                        </Typography>
                        <Typography variant="body2">
                          <b>CRIADOR</b>
                        </Typography>
                      </TableCell>
                      <TableCell>PUNTUACIONES</TableCell>
                      <TableCell>TOTAL</TableCell>
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  { ranking.ranking.map((p, i) => (
                      <ParticipantScoreRankingMorf
                        key={i}
                        participant={p}
                        section={queryParams.section}
                        isMobile={isMobile}
                      ></ParticipantScoreRankingMorf>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
        </Box>
        <Footer></Footer>
      </Box>
  );
}

export default withTheme(withStyles(styles)(RankingMorf));

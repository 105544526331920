import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos, Close } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

import ParticipantImage from 'components/pages/participant/ParticipantImage.js';
import Video from 'components/pages/contest/Video.js';

const styles = (theme) => ({
  dialog: {
    margin: -32,
  },
  title: {
    padding: '8px 32px 8px 16px',
    color: 'white',
    backgroundColor: 'black',
  },
  content: {
    padding: '0px 16px',
    minWidth: 560,
    [theme.breakpoints.down('sm')]: {
      minWidth: 285,
    },
    minHeigth: 100,
  },
  actions: {},
  img: {
    maxWidth: 320,
    width: '100%',
    height: 'auto',
    margin: 0,
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'white',
    padding: 4,
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: 'right',
    fontFamily: 'Yantramanav',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '4px 16px',
  },
});

class DialogMultimedia extends React.PureComponent {
  handleClose = () => {
    this.props.onClose();
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
          className={classes.dialog}
          onClose={this.props.onClose}
          onClick={this.props.onClick}
          scroll={this.props.scroll}
          open={this.props.open}
        >
        <DialogTitle className={classes.title}>
          {this.props.title}
          <IconButton className={classes.closeButton} onClick={this.handleClose} size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={1} gap={1}>
            <IconButton
              variant="outlined"
              size="small"
              onClick={(event) => this.props.changeParticipant(event, this.props.previous)}
              disabled={!this.props.previous}
            >
              <ArrowBackIosNew color={this.props.previous ? "secondary" : "disabled"} />
            </IconButton>
            <IconButton
              variant="outlined"
              size="small"
              onClick={(event) => this.props.changeParticipant(event, this.props.next)}
              disabled={!this.props.next}
            >
              <ArrowForwardIos color={this.props.next ? "secondary" : "disabled"} />
            </IconButton>
          </Box>

          <div>
            <Typography key="t11" mt={1} mb={1} variant="h6">
              Foto
            </Typography>

            {this.props.img1 ? (
                <ParticipantImage
                  key="i1"
                  img={this.props.img1}
                  alt={this.props.title}
                />
              ) : (
                <p>No hay imagen disponible</p>
              )
            }

            {this.props.video1 && (
              <>
                <Typography key="tv1" mt={3} mb={1} variant="h6">
                  {this.props.video1_desc || 'Video'}
                </Typography>
                <Video key="v1" source={this.props.video1} code={this.props.contest_code} />
              </>
            )}

            {this.props.video2 && (
              <>
                <Typography key="tv2" mt={3} mb={1} variant="h6">
                  {this.props.video2_desc || 'Video'}
                </Typography>
                <Video key="v2" source={this.props.video2} code={this.props.contest_code} />
              </>
            )}

            {this.props.video3 && (
              <>
                <Typography key="tv3" mt={3} mb={1} variant="h6">
                  {this.props.video3_desc || 'Video'}
                </Typography>
                <Video key="v3" source={this.props.video3} code={this.props.contest_code} />
              </>
            )}

            {this.props.video4 && (
              <>
                <Typography key="tv4" mt={3} mb={1} variant="h6">
                  {this.props.video4_desc || 'Video'}
                </Typography>
                <Video key="v4" source={this.props.video4} code={this.props.contest_code} />
              </>
            )}

            {!this.props.video1 && !this.props.video2 && !this.props.video3 && !this.props.video4 && (
              <>
                <Typography key="tv" mt={3} mb={1} variant="h6">
                  Vídeo
                </Typography>
                <p>No hay vídeo disponible</p>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={this.handleClose}
            color="primary"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

DialogMultimedia.propTypes = {
  classes: PropTypes.object.isRequired,
};
DialogMultimedia.defaultProps = {
  scroll: 'body',
};

export default withStyles(styles)(DialogMultimedia);

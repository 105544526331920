import React from "react";
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom"
import { Button } from '@mui/material'


const styles = (theme) => ({
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: "right",
    fontFamily: "Yantramanav",
    fontSize: 16,
    fontWeight: "bold",
    padding: "4px 16px",
  },
});

const RankingButton = (props) => {
  const { classes } = props

  return (
    <Link style={{ textDecoration: "none" }} to={props.url}>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
      >
        {props.title}
      </Button>
    </Link>
  );
}

export default withTheme(withStyles(styles)(RankingButton));

import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { TableCell, TableRow, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  tableRowParticipantClassified: {
    backgroundColor: "#fab31e44",
    height: 48,
  },
  cellPos: {
    fontWeight: "bold",
    backgroundColor: "black",
    color: "white",
    padding: 0,
    width: 32,
  },
  cellPosClassified: {
    fontWeight: "bold",
    backgroundColor: "#fab31e",
    color: "white",
    padding: 0,
    width: 32,
  },
  cellCollapse: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  cellCollapseActive: {
    borderBottom: "4px solid " + theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  link: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    padding: 0,
    whiteSpace: "break-spaces",
  },
  cellJumpLine: {
    whiteSpace: "break-spaces",
  },
  cellMobile: {
    margin: 0,
    padding: 0,
  },
  cellMobilePos: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  cellMobilePosClassified: {
    backgroundColor: "#fab31e",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  bold: {
    fontWeight: "bold",
  },
  multimedia: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: "4px 8px",
  },
  multimediaImg: {
    width: "32px!important",
  },
  extraWhiteCell: {
    margin: 0,
    padding: 0,
    height: 1,
  },
});

class ParticipantScoreRankingMorf extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      open: false,
    };
  }

  handleDialog = (e) => {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  handleClose = (e) => {
    this.setState({ open: false });
  };

  render() {
    const { classes, participant, section } = this.props;

    return (
      <React.Fragment>
        {this.props.isMobile ? (
          <React.Fragment>
            <TableRow>
              <TableCell
                className={classes.cellMobile}
                align="center"
                colSpan={3}
              >
                <Typography
                  className={
                    participant.classified
                      ? classes.cellMobilePosClassified
                      : classes.cellMobilePos
                  }
                  variant="body2"
                  noWrap={true}
                >
                  {participant.position}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              className={
                participant.classified
                  ? classes.tableRowParticipantClassified
                  : classes.tableRowParticipant
              }
            >
              <TableCell>
                <Typography variant="body2">
                  <Link
                    className={classes.link}
                    to={'/participant?code=' + participant.code}
                    onClick={this.handleClick}
                  >
                    {participant.horse}
                  </Link>
                </Typography>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {`${participant.livestock_holder_name} - ${participant.breeder_name}`}
                </Typography>
              </TableCell>
              <TableCell>
                {participant.scores &&
                  participant.scores.length > 0 &&
                  participant.scores.map((score) => (
                    <Typography key={score.name} variant="body2">
                      <Link
                        className={classes.link}
                        to={`/contests/morf/${score.contest_code}?main_section=secciones&section=${section}`}
                        onClick={this.handleClick}
                      >
                        {score.name}
                      </Link>{' '}
                      - {score.score}
                    </Typography>
                  ))}
              </TableCell>
              <TableCell>{participant.total_score}</TableCell>
            </TableRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableRow
              className={
                participant.classified
                  ? classes.tableRowParticipantClassified
                  : classes.tableRowParticipant
              }
            >
              <TableCell
                classes={{ root: classes.cellRoot }}
                className={
                  participant.classified
                    ? classes.cellPosClassified
                    : classes.cellPos
                }
                align="center"
              >
                {participant.position}
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Link
                    className={classes.link}
                    to={'/participant?code=' + participant.code}
                    onClick={this.handleClick}
                  >
                    {participant.horse}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.cellJumpLine} variant="body2">
                  {participant.livestock_holder_name}
                  <br />
                  {participant.breeder_name}
                </Typography>
              </TableCell>

              <TableCell>
                {participant.scores &&
                  participant.scores.length > 0 &&
                  participant.scores.map((score) => (
                    <Typography key={score.name} variant="body2">
                      <Link
                        className={classes.link}
                        to={`/contests/morf/${score.contest_code}?main_section=secciones&section=${section}`}
                        onClick={this.handleClick}
                      >
                        {score.name}
                      </Link>{' '}
                      - {score.score}
                    </Typography>
                  ))}
              </TableCell>
              <TableCell>{participant.total_score}</TableCell>
            </TableRow>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ParticipantScoreRankingMorf.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParticipantScoreRankingMorf.defaultProps = {
  isMobile: false,
};

export default withStyles(styles)(ParticipantScoreRankingMorf);
